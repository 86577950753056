<template>
    <div class="mx-12 my-6">
        <div v-if="!isDataLoaded">
            <TheSpinner />
        </div>
        <div class="d-flex flex-row" v-else>
            <div class="mx-6">
                <delete-dialog :toggleDialog="dialogDelete" itemType="schedule" v-if="deletedSchedule"  @confirm-dialog="confirmDialog"/>
                <v-card :width="leftPanelWidth">
                    <v-data-table
                        v-model="selected"
                        :headers="tableHeaders"
                        :items="tableItems"
                        single-select
                        item-key="uid"
                        class="elevation-1"
                        @click:row="showSchedule"
                        style="cursor: pointer"
                        :search="tableSearch"
                        :loading="!dataLoaded"
                        :loading-text="$t('main.loadingSchedules')"
                        :footer-props="{
                                    itemsPerPageText: $t('main.rowsPerPage'),
                                    pageText: '{0} - {1} ' + $t('main.of') + ' {2}'
                                }"
                        :no-data-text="$t('main.noDataFound')"
                        :no-results-text="$t('main.noSearchResults')"
                    >
                        <template #top>
                            <v-toolbar>
                                <v-toolbar-title>
                                    <h2 class="px-4">{{ $t('menu.buttons.schedules') }}</h2>
                                </v-toolbar-title>
                                <v-spacer />
                                <v-text-field
                                    style="width: 100px"
                                    ref="scheduleSearch"
                                    v-model="tableSearch"
                                    append-icon="mdi-magnify"
                                    :label="$t('main.search')"
                                    single-line
                                    clearable
                                    outlined
                                    hide-details
                                    dense
                                    solo
                                    color="accent"
                                ></v-text-field>
                            </v-toolbar>
                        </template>
                        <template v-slot:item.published="{ item }">
                            <span v-if="item.published">
                                <v-icon color="green">mdi-check</v-icon>
                            </span>
                            <span v-else>
                                <v-icon color="red">mdi-cancel</v-icon>
                            </span>
                        </template>
                        <template v-slot:item.actions="{ item }">
                            <!-- handle delete item dialog -->
                            <div @click.stop="clickDialog(item)">
                                <v-tooltip bottom>
                                    <template #activator="{on, attrs}">
                                        <v-icon color="red" v-bind="attrs" v-on="on">
                                            mdi-delete
                                        </v-icon>
                                    </template>
                                    <span>{{ $t('tooltip.deleteSchedule') }}</span>
                                </v-tooltip>
                            </div>
                        </template>
                    </v-data-table>
                    <v-btn color="info" class="float-end mt-6" :to="{ name: 'schedNew', params: { crudType: 'new' } }" :disabled="!noOrganisations">{{ $t('schedule.newSchedule') }}</v-btn>
                </v-card>
            </div>
            <div v-if="infoReady" class="mx-6">
                <div>
                    <schedule-profile-header :chosen-schedule="chosenSchedule" />
                    <schedule-profile-card :chosen-schedule="chosenSchedule" @ioProfile="getCurrentIoProfile"/>
                </div>
                <div class="d-flex justify-space-between mt-6">
                    <v-tooltip top >
                        <template #activator="{ on }">
                            <div v-on="on" >
                                <v-btn
                                    @click="sendEditData"
                                    color="info"
                                >{{ $t('schedule.editSchedule') }}</v-btn>
                            </div>
                        </template>

                        <span v-if="isPublished">{{ $t('schedule.publishedScheduleFrozen') }}</span>
                        <span v-else>{{ $t('schedule.editThisSchedule') }}</span>
                    </v-tooltip>
                    <div class="d-flex">
                        <div>
                            <v-tooltip top>
                                <template #activator="{ on }">
                                    <div v-on="on">
                                        <v-btn
                                            class="mx-4"
                                            @click="assignSchedule"
                                            color="info"
                                            :disabled="!isPublished"
                                        >{{ $t('schedule.assignNodes') }}</v-btn>
                                    </div>
                                </template>
                                <span>{{ $t('schedule.publishedSchedule') }}</span>
                            </v-tooltip>
                        </div>
                        <v-btn color="info" @click="sendProfileToCalendar">{{ $t('schedule.viewInCalendar') }} &nbsp;&nbsp;<v-icon>mdi-calendar</v-icon></v-btn>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import {cloneDeep as _cloneDeep} from "lodash";
import ScheduleProfileCard from "@/components/Schedule/ScheduleProfileCard";
import ScheduleProfileHeader from "@/components/Schedule/ScheduleProfileHeader";
import {editPublishedSchedule} from "@/services/apiHelpers";
import DeleteDialog from "@/components/Schedule/DeleteDialog.vue";
import TheSpinner from "@/components/Standard/TheSpinner.vue";

export default {
    name: "SchedulesInfo",
    components: {
        TheSpinner,
        DeleteDialog,
        ScheduleProfileCard,
        ScheduleProfileHeader
    },
    async mounted() {
        if(this.stateActiveOrg){
            await this.initSched()
            .then(async () => {
              this.populateTable();

              if (this.$route.query.schedule_id) {
                const data = {
                  id: parseInt(this.$route.query.schedule_id),
                  version: this.$route.query.schedule_version ? parseInt(this.$route.query.schedule_version) : -1,
                  type: 'query'
                };
                this.selected = [{uid: this.tableItems.find((el) => el.id === data.id).uid}];
                await this.showSchedule(data);
              }
            });
        }
    },
    data() {
        return {
            dialogDelete: false,
            dataLoaded: false,
            infoReady: false,
            chosenSchedule: {
                active: false,
                published: false,
                name: '',
                id: -1,
                version: -1,
                profileId: -1,
                schedules: []
            },
            tableItems: [],
            tableSearch: '',
            chosenIo: 0,
            chosenProfile: 0,
            selected: [{uid:44}],
            deletedSchedule: null
        };
    },
    computed: {
        ...mapGetters({'getSchedules': 'sched/getSchedules', 'schedError': 'sched/getError', 'getCurrent': 'sched/getSingleSchedule',
            'stateActiveOrg': 'auth/StateActiveOrganisation'}),
        isPublished() {
            return this.chosenSchedule.published;
        },
        tableHeaders(){
            return [
                { text: this.$t('schedule.schedule'), value: 'id' },
                { text: this.$t('schedule.scheduleName'), value: 'name' },
                { text: 'Published', value: 'published' },
                { text: 'Actions', value: 'actions'}
            ];
        },
        leftPanelWidth() {
            const vtf = this.$vuetify.breakpoint.name;
            switch(vtf) {
                case 'xl':
                    return '650';
                case 'lg':
                    return '500';
                default:
                    return '300';
            }
        },
        activeOrg(){
            return this.stateActiveOrg;
        },
        noOrganisations(){
            return this.stateActiveOrg && Object.keys(this.stateActiveOrg).length === 0;
        },
        isDataLoaded(){
            return this.dataLoaded;
        }
    },
    methods: {
        ...mapActions({'initSched': 'sched/Schedules'}),
        getCurrentIoProfile (data) {
            this.chosenIo = data.currentIo;
            this.chosenProfile = data.currentProfile;
        },
        async showSchedule(data, row) {
            if (row){
                row.select(true);
            }
            let thisSched;
            let newObject = {
                schedule_id: -1,
                schedule_version: -1,
            }
            if ("type" in data) {
                // then we get the schedule info from a query and use the data passed from created()
                newObject.schedule_id = data.id;
                newObject.schedule_version = data.version;
            } else {
                // else we use the data passed in from clicking a row
                newObject.schedule_id = data.id;
                newObject.schedule_version = data.v_id;
            }
            await this.$store.dispatch('sched/searchSchedule', newObject);
            thisSched = _cloneDeep(this.getCurrent);
            if (!this.schedError) {
                this.chosenSchedule.name = thisSched.name;
                this.chosenSchedule.id = thisSched.id;
                this.chosenSchedule.uid = thisSched.uid;
                this.chosenSchedule.version = thisSched.version;

                const usedIOs = thisSched.schedules.map(x => parseInt(x.io_id.split('.')[1]));
                const nrOfIos = Math.max(...usedIOs);

                this.nrOfIoTabs = nrOfIos;

                // fill the empty io's with empty profiles
                this.chosenSchedule.schedules = [];

                for (let n = 1; n < nrOfIos + 1; n++) {
                    if (!usedIOs.includes(n)) {
                        this.chosenSchedule.schedules.splice(n - 1, 0, {
                            io_id: `out.${n}`,
                            profiles: []
                        });
                    } else {
                        const schedule = thisSched.schedules.find((x) => x.io_id === `out.${n}`);
                        this.chosenSchedule.schedules.splice(n - 1, 0, {...schedule});
                    }
            }

                // this.chosenSchedule.schedules = _cloneDeep(thisSched.schedules);
                this.chosenSchedule.active = thisSched.active;
                this.chosenSchedule.published = thisSched.published;
            } else {
                console.log(this.schedError);
                await this.$store.dispatch('sched/resetError');
            }
            const querydata = {
                id: parseInt(this.$route.query.schedule_id || -1),
                version: parseInt(this.$route.query.schedule_version || -1),
                uid: parseInt(this.$route.query.schedule_uid || null),
            }
            if (querydata.id !== thisSched.id) {
                await this.$router.push({name: 'schedInfo', query: {schedule_id: thisSched.id, schedule_version: thisSched.version}, replace: true});
            }
            this.infoReady = true;

        },
        populateTable(){
            this.tableItems = [];
            this.getSchedules.forEach((elem) => {
                const item = {
                    id: elem.id,
                    uid: elem.uid,
                    v_id: elem.version,
                    name: elem.name,
                    published: elem.published
                };
                if (elem.active) this.tableItems.push(item);
            });
            this.dataLoaded = true;
        },
        sendEditData() {
            const scheduleObject = {
                schedule_id: this.chosenSchedule.id,
                io_id: this.chosenIo,
                name: this.chosenSchedule.name,
                profile_id: this.chosenProfile,
                version: this.chosenSchedule.version,
                scheduleInputType: 'edit'
            };
            this.$router.push({ name: 'schedNew',
                params: { crudType: scheduleObject.scheduleInputType },
                query: { schedule_id: `${scheduleObject.schedule_id}`,
                    schedule_version: `${scheduleObject.version}`,
                    io_id: `${scheduleObject.io_id}`,
                    profile_id: `${scheduleObject.profile_id}` }});
        },
        sendProfileToCalendar() {
            const scheduleObject = {
                schedule_id: this.chosenSchedule.id,
                schedule_version: this.chosenSchedule.version,
                io_id: this.chosenIo,
                profile_id: this.chosenProfile,
            };
            this.$router.push({ name: 'schedOverview',
                params: { scheduleProfile: this.chosenSchedule},
                query: { schedule_id: `${scheduleObject.schedule_id}`,
                    schedule_version: `${scheduleObject.schedule_version}`,
                    io_id: `${scheduleObject.io_id}`,
                    profile_id: `${scheduleObject.profile_id}` }});
        },
        assignSchedule() {
            this.$router.push({name: 'assignNodes',
                params: { chosenSchedule: this.chosenSchedule },
                query: { schedule_id: `${this.chosenSchedule.id}`, schedule_version: `${this.chosenSchedule.version}` }})
        },
        clickDialog(item){
            this.dialogDelete = true;
            this.deletedSchedule = item;
        },
        async confirmDialog(data) {
            if (data) {
                await editPublishedSchedule(this.stateActiveOrg, {
                    scheduleUid: this.deletedSchedule.uid,
                    active: false
                });
                this.populateTable();
                if (this.$route.path !== 'schedInfo') this.$router.push({name: 'schedInfo', replace: true});
                this.$router.go(0);
            }
            this.dialogDelete = false;
            this.deletedSchedule = null;
        }
    },
    watch:{
        getSchedules(newV, oldV){
            if(oldV && oldV.length !== newV.length) {
                this.populateTable();
            }
        }
    }
}
</script>

<style scoped>
input {
    border: 1px grey solid;
    padding: 5px 10px 5px 10px;
    margin: 20px;
}
</style>
